import XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';

const excelDownloader = (json_data, filename) => {
    const data = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    XLSX.writeFile(wb,`${filename}`);
};

const excelDownloaderWithAdditionalSheet = (json_data, additional_data, filename) => {
    let data = null;
    let wb = null;
    let format = 'dd/mm/yyyy';

    if (filename.includes('AttendanceVerification')) {
        format = 'hh:mm mm/dd/yyyy';
        data = XLSX.utils.json_to_sheet(json_data, {cellDates: true, dateNF: format});
    } else {
        data = XLSX.utils.json_to_sheet(json_data);
    }

    wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    
    // additional data is objects, the key would be the sheet name and the value of key should be array of objects
    for (const key in additional_data) {
        if (additional_data[key].data && additional_data[key].data.length > 0) {
            // formatting cell into DATE format
            for (const element of additional_data[key].data) {                
                if (element['Tipe Data'] && element['Tipe Data'].includes('Date')) {
                    element['Contoh'] = new Date(element['Contoh']);
                }
            }
            const newDataSheet = XLSX.utils.json_to_sheet(additional_data[key].data, {cellDates: true, dateNF: format});            
            XLSX.utils.book_append_sheet(wb, newDataSheet, additional_data[key].sheet_name);
        }
    }
    XLSX.writeFile(wb,`${filename}`);
};

const detailWorkerImgChecker = (oldimg, isEdit, newImg) => {
    let imgMime = null;

    if(oldimg && !isEdit) {
        const splitImg = oldimg.split(".");
        imgMime = splitImg[splitImg.length - 1];
    } else if(isEdit) {
        const splitImg = newImg.type.split("/");
        imgMime = splitImg[splitImg.length - 1];
    }
        
    if (imgMime && ["png", "jpg", "jpeg", "svg", "gif"].includes(imgMime)) {
        return oldimg;
    }

    return "/images/documents-icon.png";
};

const contentType = (url) => {
    const contentType = url.split(".").slice(-1)[0];

    if (['doc', 'docx'].includes(contentType)) {
        return "isShowPreviewOfficeModal";
    } else if (['pdf'].includes(contentType)) {
        return "isShowPreviewPDFModal";
    } else {
        return "isShowPreviewImageModal";
    }
};

const sortString = (data, column, type) => {
    return data.sort((a, b) => {
        let fa = a[column].toString().toLowerCase(),
            fb = b[column].toString().toLowerCase();
    
        if (type === 'asc') {
            return -fa.localeCompare(fb);
        } else {
            return fa.localeCompare(fb);
        }
    });
};

const downloadAsZip = async (filesUrl=[], zip_filename="files.zip") => {
    const zip = new JSZip();

    const files = filesUrl;
    let index = 1;

    const downloadFile = async (fileUrl) => {
        return await axios({
            method: 'get',
            url: fileUrl,
            responseType: 'blob'  // Set response type as blob
        })
            .then((response) => {
                const fileName = fileUrl.split('/').pop();
                zip.file(`(File ${index}) ${fileName}`, response.data); // Add file to zip
                index++;
            })
            .catch((error) => {
                console.error('Error downloading file:', fileUrl, error);
            });
    };

    const filePromises = files.map((fileUrl) => downloadFile(fileUrl));

    try {
        // Wait for all files to be downloaded
        await Promise.all(filePromises);

        // Generate the zip file
        const content = await zip.generateAsync({ type: 'blob' });

        // Save the zip file using FileSaver.js
        saveAs(content, zip_filename);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

export {
    excelDownloader, detailWorkerImgChecker, sortString, contentType, excelDownloaderWithAdditionalSheet, downloadAsZip
};
