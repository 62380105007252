<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable uk-heigth-1-1">
        <div class="uk-grid-column-small uk-grid-row-large uk-child-width-1-2@m" uk-grid>
            <div v-if="getRole() !== 'supervisor'">
                <div v-if="isShow">
                    <div class="location-header" style="cursor:pointer" @click="showModal('attendance-filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20">
                        {{ moment(meta.start_date).format('DD MMM YYYY') }} {{ (meta.end_date ? (' - ' + moment(meta.end_date).format('DD MMM YYYY')) : '')}},
                        {{ cityName }},
                        {{ officeName }}
                    </div>
                </div>
                <div v-else>
                    <div class="location-header" style="cursor:pointer" @click="showModal('attendance-filter')">
                        <img :src="`${images}/mi_filter.svg`" alt="" width="20"> Select filter {{ meta.start_date }}
                    </div>
                </div>
                <p v-if="isShow" class="reset-filter" @click="resetFilter">Reset Location Filter</p>
            </div>
            <div v-else>
                <div v-if="isShow">
                    <div class="location-header">
                        <img :src="`${images}/shipper-location.png`" alt="" width="20"> {{ company_office.city || 'n/a' }}
                    </div>
                    <div class="location-header">
                        <img :src="`${images}/shipper-storage.png`" alt="" width="20"> {{ company_office.name || 'n/a' }}
                    </div>
                </div>
            </div>
            <div v-show="isShow && $can('EDIT', 'worker-attendance')">
                <div style="overflow:auto">
                    <div class="report-download" style="cursor:pointer;float:right" @click="showModal('download-modal')">
                        <img :src="`${images}/download.png`" alt="" width="25">
                        Download Report
                    </div>
                    <div class="report-download" style="cursor:pointer;float:right">
                        <button
                            class="uk-button uk-button-default"
                            style="
                                border-radius: 5px;
                                width: 275px;
                                border: 1px solid #0ABAB5;
                                color: #FFFFFF;
                                background-color: #0ABAB5;
                                margin-right: 1rem;
                                "
                            @click="showModal('download-upload-attendance-verification')"
                        >
                            Download Attendance Verification
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isShow && $can('READ', 'worker-attendance')" class="uk-margin-top uk-flex uk-child-width-1-6" style="gap: 15px;">
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid black;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead">{{ totalWorker }}</p>
                <p class="uk-margin-remove uk-padding-remove">Total</p>
                <p class="uk-margin-remove uk-padding-remove">Mitra</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid #AB47BC;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8" style="color: #AB47BC;"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" style="color: #AB47BC;">{{ totalWorkerOvertime }}</p>
                <p class="uk-margin-remove uk-padding-remove">Total</p>
                <p class="uk-margin-remove uk-padding-remove">Lembur</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid #FFC737;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8" style="color: #FFC737;"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" style="color: #FFC737;">{{ totalWorkerLeave }}</p>
                <p class="uk-margin-remove uk-padding-remove">Total</p>
                <p class="uk-margin-remove uk-padding-remove">Izin</p>
            </div>
        </div>

        <div class="uk-flex uk-flex-between uk-margin-remove-top" uk-grid>
            <div class="uk-inline">
                <div class="uk-inline uk-margin-small-right" v-show="isShow">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" v-model="meta.worker_name" @keyup.enter="(e) => changeMeta(e, 'worker_name')" type="text" placeholder="Search worker by name">
                </div>
                <div class="uk-inline uk-margin-small-right" v-show="isShow">
                    <select
                        name="Updated By Filter"
                        class="uk-select"
                        id="form-stacked-select"
                        v-model="meta.updated_filter"
                        @change="(e) => changeMeta(e, 'updated_filter')"
                    >
                        <option value="" disabled selected>Select attendance</option>
                        <option :value=null>All Attendance</option>
                        <option :value="true">Updated Attendance</option>
                    </select>
                </div>
                <div class="uk-inline uk-margin-small-bottom" v-show="isShow">
                    <select
                        name="Sort"
                        class="uk-select"
                        id="form-stacked-select"
                        v-model="meta.sort"
                        @change="(e) => changeMeta(e, 'sort')"
                    >
                        <option :value="-1">Latest</option>
                        <option :value="1">Oldest</option>
                    </select>
                </div>
            </div>
            <div class="uk-inline">
                <div class="uk-inline" v-show="isShow && $can('EDIT', 'worker-attendance')">
                    <div v-if="!isLock">
                        <button
                            class="uk-button uk-button-default"
                            style="
                                border-radius: 5px;
                                width: 210px;
                                border: 1px solid #0275D8;
                                color: #0275D8;
                                "
                            @click="isLock ? isLock = false : isLock = true"
                        >
                            <img :src="`${images}/multi-select.svg`" alt="" width="18" style="margin: 0px 7px 3px 0px">
                            Multi-select Mitra
                        </button>
                    </div>
                    <div v-else>
                        <div class="uk-inline">
                            <button
                                class="uk-button uk-button-danger uk-border-rounded uk-margin-small-right"
                                type="button"
                                @click="isLock ? isLock = false : isLock = true"
                            >Cancel</button>
                            <button
                                class="uk-button uk-button-primary uk-border-rounded"
                                type="button"
                                @click="showModal('confirmation-multiple-lock-box')"
                            >
                                <span class="uk-margin-small-right"><img :src="`${images}/lock.svg`" alt="" width="18"></span>
                                Lock
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-operation','admin-client'])">
                <div class="uk-inline" v-show="isShow != ''">
                    <button class="uk-button uk-button-primary uk-border-rounded" @click="checkRecap">Generate Salary</button>
                </div>
            </div> -->
        </div>
        <div v-if="isLock">{{ user_company_id.length }} User selected</div>

        <div v-if="$can('READ', 'worker-attendance')" class="uk-card uk-card-default uk-margin" v-show="isShow">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th v-if="isLock" class="uk-text-center"></th>
                            <th class="uk-table-expand">Full Name</th>
                            <th class="uk-table-expand uk-text-center">No KTP</th>
                            <th class="uk-table-expand uk-text-center">Mitra ID</th>
                            <th class="uk-table-expand uk-text-center">Total Work Days</th>
                            <th class="uk-text-center">Total Work<br>Hours</th>
                            <th class="uk-text-center">Total<br>Overtime<br>(hrs)</th>
                            <th class="uk-text-center">Total<br>Leaves<br>(times)</th>
                            <th class="uk-text-center">Office</th>
                            <th class="uk-text-center">Attendance Method</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="14"/>
                    <tbody v-else-if="recaps.docs.length>0">
                        <tr v-for="(worker, i) in recaps.docs" :key="i">
                            <td v-if="isLock"><input class="uk-checkbox" :id="i" type="checkbox" @change="cbListCandidate($event, worker)"></td>
                            <td class="uk-text-left uk-text-justify">
                                <a
                                    style="text-decoration: none;"
                                    @click="detailCandidate(worker, meta)"
                                    href="javascript:void(0)"
                                >{{ worker.employee_name || '-' }}</a>
                            </td>
                            <!-- <td class="uk-text-center">
                                <label-status
                                    :type="'worker'"
                                    :status="worker.job_partner.status"
                                    :agreement_link="worker.job_partner.agreement" />
                            </td> -->
                            <td class="uk-text-center">{{ worker.identity_number ? worker.identity_number : '-' }}</td>
                            <td class="uk-text-center">{{ worker.mitra_id }}</td>
                            <td class="uk-text-center">{{ worker.workdays }}</td>
                            <td class="uk-text-center">{{ worker.time_spent }}</td>
                            <td class="uk-text-center">{{ worker.overtime_work_hour_spv }}</td>
                            <td class="uk-text-center">{{ worker.leaves_spent }}</td>
                            <td class="uk-text-center">{{ worker.wh_name }}</td>
                            <td class="uk-text-center">{{ filterAttendanceMethods(worker.attendance_methods) }}</td>
                            <!-- <td class="uk-text-center">{{ worker.absent_hour || '-' }}</td>
                            <td class="uk-text-center">{{ formatAbsolute(worker.leaves_hour) }}</td>
                            <td v-if="worker.checkin_time == null || worker.checkout_time == null" class="uk-text-center"> - </td>
                            <td v-else class="uk-text-center">{{ calculateFee(worker) }}</td>
                            <td class="uk-text-center">{{ formatCurrency(worker.distance_meter_checkin) }}</td>
                            <td class="uk-text-center">{{ formatCurrency(worker.distance_meter_checkout) }}</td>
                            <td class="uk-text-left" style="font-size: 14px">{{ worker.checkin_notes }}</td>
                            <td class="uk-text-left" style="font-size: 14px">{{ worker.checkout_notes }}</td>
                            <td
                                v-if="worker.salary && consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-marketing'])"
                                class="uk-text-center"
                            >
                                <span class="uk-label" style="font-size:12px">{{ worker.salary.salary_batch_id.name }}</span>
                            </td>
                            <td
                                v-else-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd','admin-marketing'])"
                                class="uk-text-center"
                            > - </td>
                            <td class="uk-text-center">
                                <a :href="worker.job_partner.document" target="_blank" rel="noopener noreferrer">View</a>
                            </td>
                            <td class="uk-text-center">{{ worker.updated_by ? worker.updated_by : "-" }}</td>
                            <td v-if="consist(user_cred.roles, ['superadmin-tech','superadmin-product','admin-bd'])">
                                &lt;!&ndash; editable button &ndash;&gt;
                                <button
                                    v-if="worker.updated_by == 'UserAttendance'|| worker.updated_by == null"
                                    style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#0275D8"
                                    class="uk-button-primary"
                                    @click="selectData(worker)"
                                >Edit</button>
                                &lt;!&ndash; disabled button &ndash;&gt;
                                <button
                                    v-else
                                    style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#979797"
                                    class="uk-button-primary"
                                >Edit</button>
                            </td> -->
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="14" :empty-text="'Tidak ada attendance'"/>
                </table>
            </div>
            <pagination
                :total-data="recaps.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized read data from this page</p>
                    </span>
                </div>
            </div>
        </div>

        <div id="date-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('date-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input Date Range for Which you Want to See Details</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">From</label>
                        <div class="uk-form-controls">
                            <datepicker
                                name="Start Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                v-model="meta.start_date"
                                :disabled-dates="disabled_start_date"
                            ></datepicker>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">To (optional)</label>
                        <div class="uk-form-controls">
                            <datepicker
                                name="End Date"
                                placeholder="dd/mm/yyy"
                                input-class="uk-input"
                                v-model="meta.end_date"
                            ></datepicker>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setDate">Apply</button>
                </div>
            </div>
        </div>

        <div id="attendance-filter" class="attendance-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('location-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Select filter for attendance list</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">From</label>
                        <div class="uk-form-controls">
                            <datetime
                                id="start_date"
                                name="start_date"
                                input-class="uk-input"
                                placeholder="dd/mm/yyy"
                                :max-datetime="datetime.now().toISODate()"
                                type='date'
                                v-model="meta.start_date"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">To (optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                id="end_date"
                                name="end_date"
                                input-class="uk-input"
                                placeholder="dd/mm/yyy"
                                type='date'
                                v-model="meta.end_date"
                                :min-datetime="datetime.fromISO(meta.start_date).plus({days: 1}).toISODate()"
                                :max-datetime="datetime.now().toISODate()"
                                value-zone="local"
                            ></datetime>
                        </div>
                    </div>
                    <div v-if="meta.start_date" class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select-1">City</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office City"
                                class="uk-select"
                                id="form-stacked-select-1"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="" selected>All Cities</option>
                                <option
                                    v-for="(city, index) in cities"
                                    :key="index"
                                    :value="city"
                                >{{ city.name || '-' }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin" v-if="selected_city != ''">
                        <label class="uk-form-label" for="form-stacked-select-2">Office</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office Property"
                                class="uk-select"
                                id="form-stacked-select-2"
                                v-model="company_office"
                            >
                                <option value="" selected>All Offices</option>
                                <option
                                    v-for="(office, index) in filtered_offices"
                                    :key="index"
                                    :value="office"
                                >{{ office.name || '-' }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="applyFilter">Apply</button>
                </div>
            </div>
        </div>

        <div id="location-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('location-filter')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Input property for which you want to see details</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">City</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office City"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="selected_city"
                                @change="filterOffice"
                            >
                                <option value="" disabled selected>Choose City</option>
                                <option
                                    v-for="(city, index) in cities"
                                    :key="index"
                                    :value="city"
                                >{{ city.name || '-' }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin" v-if="selected_city != ''">
                        <label class="uk-form-label" for="form-stacked-select">Property</label>
                        <div class="uk-form-controls">
                            <select
                                name="Office Property"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="company_office"
                            >
                                <option value="" disabled selected>Choose Property</option>
                                <option
                                    v-for="(office, index) in filtered_offices"
                                    :key="index"
                                    :value="office"
                                >{{ office.name || '-' }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="setLocation">Apply</button>
                </div>
            </div>
        </div>

        <div id="month-filter" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('month-filter')"></button>
                <div class="uk-modal-header">
                    <h2 v-if="reportMonthly && userReport" class="uk-modal-title uk-text-center">Choose the data for user monthly report</h2>
                    <h2 v-else-if="reportMonthly" class="uk-modal-title uk-text-center">Choose the data for monthly report</h2>
                    <h2 v-else-if="userReport" class="uk-modal-title uk-text-center">Choose the data for user weekly report</h2>
                    <h2 v-else class="uk-modal-title uk-text-center">Choose the data for weekly report</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin" v-if="userReport">
                        <label class="uk-form-label" for="form-stacked-select">User</label>
                        <div class="uk-form-controls">
                            <select
                                name="User Name"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="userName"
                            >
                                <option value="" disabled selected>Select user</option>
                                <option v-for="(user, index) in userList" :key="index" :value="user.fullname">{{ user.fullname }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">Month</label>
                        <div class="uk-form-controls">
                            <select
                                name="Month Filter"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="selected_month"
                                @change="(e) => changeMeta(e, 'month')"
                            >
                                <option value="" disabled selected>Select month</option>
                                <option value=1>Januari</option>
                                <option value=2>Februari</option>
                                <option value=3>Maret</option>
                                <option value=4>April</option>
                                <option value=5>Mei</option>
                                <option value=6>Juni</option>
                                <option value=7>Juli</option>
                                <option value=8>Agustus</option>
                                <option value=9>September</option>
                                <option value=10>Oktober</option>
                                <option value=11>November</option>
                                <option value=12>Desember</option>
                            </select>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-select">Year</label>
                        <div class="uk-form-controls">
                            <select
                                name="Year Filter"
                                class="uk-select"
                                id="form-stacked-select"
                                v-model="selected_year"
                                @change="(e) => changeMeta(e, 'year')"
                            >
                                <option value="" disabled selected>Select year</option>
                                <option value=2020>2020</option>
                                <option value=2021>2021</option>
                                <option value=2022>2022</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <!-- <download-excel
                        class="uk-button uk-button-primary uk-margin-small-left"
                        :fetch = "downloadDataWeekly"
                        :fields = "json_fields_weekly_report"
                        :before-generate = "startDownload"
                        :before-finish = "finishDownload"
                        :name = "generateWeeklyFileName()"
                    > Apply</download-excel> -->
                    <div
                        v-if="!reportMonthly"
                        class="uk-button uk-button-primary uk-margin-small-left"
                        @click="downloadNewExcelMonthly"
                    > Apply</div>
                    <div
                        v-else
                        class="uk-button uk-button-primary uk-margin-small-left"
                        @click="downloadNewDaytoDayExcelMonthly"
                    > Apply</div>
                </div>
            </div>
        </div>

        <div id="show-detail" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('show-detail')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">{{ selected_data.user.fullname }} Attendance on {{ formatDate(selected_data.date) }}</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Location</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Company Office"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    :placeholder="`${selected_data.company_office.code} ${selected_data.company_office.name} ${selected_data.company_office.city}`"
                                    disabled
                                >
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">Role</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Role"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    :placeholder="`${selected_data.job_partner.role != null ? selected_data.job_partner.role.name : '-'}`"
                                    disabled
                                >
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Payment Unit</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Payment Unit"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    :placeholder="selected_data.fee_normal_unit || '-'"
                                    disabled
                                >
                            </div>
                            <br>
                            <div class="uk-form-controls">
                                <input
                                    name="Break Hour"
                                    class="uk-checkbox"
                                    id="form-stacked-text"
                                    type="checkbox"
                                    v-model="selected_data.breakhour_include"
                                    disabled
                                > Break-Hour Included
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Check-In Time</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="datetime"
                                    name="Check In Time"
                                    v-model="selected_data.checkin_time"
                                    placeholder="-"
                                    @input="adjustCheckInTime"
                                ></datetime>
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">Check-Out Time</label>
                            <div class="uk-form-controls">
                                <datetime
                                    input-class="uk-input"
                                    type="datetime"
                                    name="Check Out Time"
                                    v-model="selected_data.checkout_time"
                                    placeholder="-"
                                    @input="adjustCheckOutTime"
                                ></datetime>
                            </div>
                        </div>
                        <span class="uk-text-small uk-text-danger" v-show="timeIsInvalid">Re-check time input!</span>
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Normal Worktime (hrs)</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Normal Worktime"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    :placeholder="formatAbsolute(selected_data.normal_work_hour)"
                                    disabled
                                >
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">Normal Worktime Payment (Rp)</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Normal Worktime Fee"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    :placeholder="selected_data.fee_normal_hourly"
                                    disabled
                                >
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-column-1-2@m">
                            <label class="uk-form-label" for="form-stacked-text">Overtime (hrs)</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Overtime"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    v-model="selected_data.overtime_work_hour_spv"
                                >
                            </div>
                            <label class="uk-form-label" for="form-stacked-text">Overtime Payment (Rp)</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Overtime Fee"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    :placeholder="selected_data.fee_overtime_hourly"
                                    disabled
                                >
                            </div>
                        </div>
                        <label class="uk-form-label" for="form-stacked-text">Overall Worktime (hrs)</label>
                        <div class="uk-form-controls">
                            <input
                                name="Total Work Time"
                                class="uk-input"
                                id="form-stacked-text"
                                type="text"
                                :placeholder="calculateTotalWorkTime(selected_data.normal_work_hour, selected_data.overtime_work_hour)"
                                disabled
                            >
                        </div>
                        <div class="uk-width-1-1 uk-column-1-1@m">
                            <!-- <label class="uk-form-label" for="form-stacked-text">Absent (hrs)</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Absent"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    v-model="selected_data.absent_hour"
                                >
                            </div> -->
                            <label class="uk-form-label" for="form-stacked-text">Leaves (hrs)</label>
                            <div class="uk-form-controls">
                                <input
                                    name="Leaves"
                                    class="uk-input"
                                    id="form-stacked-text"
                                    type="text"
                                    v-model="selected_data.leaves_hour"
                                >
                            </div>
                        </div>
                        <label class="uk-form-label" for="form-stacked-text">Total Payment (Rp)</label>
                        <div class="uk-form-controls">
                            <input
                                name="Total Payment"
                                class="uk-input"
                                id="form-stacked-text"
                                type="text"
                                :placeholder="calculateFee(selected_data)"
                                disabled
                            >
                        </div>
                        <div>
                            <br>
                            Edited By
                            <br>
                            {{ selected_data.updated_by }}
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button :disabled="timeIsInvalid" class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="updateDataConfirm">Save</button>
                </div>
            </div>
        </div>

        <div id="confirmation-edit-box" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-edit-box')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left">Do you want to edit?</h2>
                </div>
                <div class="uk-modal-body">
                    <p>You’re about to edit {{ selected_data.user.fullname }} attendance for {{ formatDate(selected_data.date) }}!
                        After you edit, this attendance will be locked for payment.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="declineConfirmationEdit">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="acceptConfirmationEdit">Yes</button>
                </div>
            </div>
        </div>

        <div id="confirmation-edit-box-2" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-edit-box-2')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left">Are you sure?</h2>
                </div>
                <div class="uk-modal-body">
                    <p>You’re about to edit {{ selected_data.user.fullname }} attendance for {{ formatDate(selected_data.date) }}!
                        After you edit, this attendance will be locked for payment.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="declineConfirmationEdit2">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="acceptConfirmationEdit2">Yes</button>
                </div>
            </div>
        </div>

        <div id="confirmation-heads-up" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-heads-up')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Heads Up! This attendance will be locked for payment</h2>
                </div>
                <div class="uk-modal-body uk-text-center">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="hideModal('confirmation-heads-up')">Cancel</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="lockData">Confirm</button>
                </div>
            </div>
        </div>

        <div id="success-edit" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('success-edit')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left">Success!</h2>
                </div>
                <div class="uk-modal-body">
                    <p>{{ selected_data.user.fullname }} attendance for {{ formatDate(selected_data.date) }}
                        is now locked for payment.</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('success-edit')">Ok</button>
                </div>
            </div>
        </div>

        <div id="download-modal-user" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('download-modal')"></button>
                <div class="uk-modal-body">
                    <p>Pick the report's period!</p>
                    <div style="overflow:auto">
                        <div style="cursor:pointer;float:left" @click="downloadNewExcelDaily">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            Daily Report
                        </div>
                    </div>
                    <div style="overflow:auto">
                        <div style="cursor:pointer;float:left" @click="showModal('month-filter')">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            Weekly Report
                        </div>
                    </div>
                    <div style="overflow:auto">
                        <div style="cursor:pointer;float:left" @click="showModal('month-filter'); reportMonthly=true">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            Monthly Report
                        </div>
                    </div>
                    <div style="overflow:auto">
                        <div style="cursor:pointer;float:left" @click="showModal('month-filter'); userReport=true">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            User Weekly Report
                        </div>
                    </div>
                    <div style="overflow:auto">
                        <div style="cursor:pointer;float:left" @click="showModal('month-filter'); reportMonthly=true; userReport=true">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            User Monthly Report
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('download-modal')">Back</button>
                </div>
            </div>
        </div>

        <div id="download-modal" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('download-modal')"></button>
                <div class="uk-modal-body">
                    <p class="uk-text-center uk-text-bold uk-text-large">Please Select the type of report that you wish to download</p>
                    <hr>
                    <div style="overflow:auto">
                        <div style="cursor:pointer;float:left" @click="AllUserReport">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            All User Report
                        </div>
                    </div>
                    <hr>
                    <div style="overflow:auto">
                        <div style="cursor:pointer;float:left" @click="isShowDetailUser ? isShowDetailUser = false : isShowDetailUser = true">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            User Detail Report
                        </div>
                    </div>
                    <hr>
                    <div style="overflow:auto">

                        <div v-show="!isLoading" style="cursor:pointer;float:left" @click="DailyAttendanceReport">
                            <img :src="`${images}/excel.png`" alt="" width="20">
                            Daily Attendance Report
                        </div>
                        <div v-show="isLoading" uk-spinner :ratio="1"></div>
                    </div>
                    <hr>
                    <div v-show="isShowDetailUser">
                        <div v-for="(worker, index) in recaps.docs" :key="index" style="overflow:auto" class="uk-margin-left">
                            <div style="cursor:pointer;float:left" @click="spesificUserReport(worker.employee_name, worker.job_partner_id)">
                                <img :src="`${images}/excel.png`" alt="" width="20">
                                {{ worker.employee_name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('download-modal')">Back</button>
                </div>
            </div>
        </div>

        <div id="warning-modal" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('warning-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Warning!</h2>
                </div>
                <div class="uk-modal-body">
                    <p>There is Attendance Recap that has been included into another batch!</p>
                    <p v-if="salary_data != null">Upcoming Salary Batch summary
                        <br>Users : {{ salary_data.user_count }}
                        <br>Total Payment : {{ formatCurrency(parseInt(salary_data.total_payment)) }}
                    </p>
                </div>
                <div class="uk-modal-footer uk-text-right" v-if="salary_data != null">
                    <button class="uk-button uk-button-danger uk-modal-close uk-margin-right" type="button" @click="hideModal('warning-modal')">Cancel</button>
                    <button class="uk-button uk-button-primary" type="button" @click="showModal('salary-modal')" v-if="salary_data.user_count > 0">Continue</button>
                </div>
            </div>
        </div>

        <div id="salary-modal" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('salary-modal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Salary Batch</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label" for="form-stacked-text">Salary Batch Name</label>
                        <div class="uk-form-controls">
                            <input
                                class="uk-input"
                                name="name"
                                type="text"
                                v-model="formSalary.name"
                            >
                        </div>
                    </div>
                    <p v-if="salary_data != null">Upcoming Salary Batch summary
                        <br>Users : {{ salary_data.user_count }}
                        <br>Total Payment : {{ formatCurrency(parseInt(salary_data.total_payment)) }}
                    </p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-modal-close uk-margin-right" type="button" @click="hideModal('salary-modal')">Cancel</button>
                    <button class="uk-button uk-button-primary" type="button" @click="createSalaryBatch">Create</button>
                </div>
            </div>
        </div>

        <div id="confirmation-multiple-lock-box" class="confirmation-multiple-lock-box" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-multiple-lock-box')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left">Confirmation</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Are you sure want to proceed?</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="declineConfirmationLockUser">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="acceptConfirmationLockUser">Yes</button>
                </div>
            </div>
        </div>

        <div id="download-upload-attendance-verification" class="download-upload-attendance-verification" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('download-upload-attendance-verification')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-bold">Please select</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <button
                                :disabled="downloadAttendanceIsLoading"
                                class="uk-button uk-button-default"
                                style="
                                    border-radius: 5px;
                                    width: 100%;
                                    border: 1px solid #0275D8;
                                    color: white;
                                    background-color: #0275D8;
                                    "
                                @click="downloadAttendanceVerification"
                            >
                                <img :src="`${images}/download-attendance-verification.svg`" alt="" width="18" style="margin: 0px 7px 3px 0px">
                                {{ downloadAttendanceText }}
                            </button>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <button
                                class="uk-button uk-button-default"
                                style="
                                    border-radius: 5px;
                                    width: 100%;
                                    border: 1px solid #0275D8;
                                    color: #0275D8;
                                    "
                                @click="triggerUpload()"
                            >
                                <img :src="`${images}/upload-attendance-verification.svg`" alt="" width="18" style="margin: 0px 7px 3px 0px">
                                <input
                                    ref="upload"
                                    style="display: none"
                                    type="file"
                                    accept=".xls,.xlsx"
                                    @change="uploadFileVerification"
                                />
                                Upload
                            </button>
                            <!-- <button
                                class="uk-button uk-button-default"
                                style="
                                    border-radius: 5px;
                                    width: 100%;
                                    border: 1px solid #0275D8;
                                    color: #0275D8;
                                    "
                                @click="showModal('loader-upload-verification-attendance')"
                            >
                                <img :src="`${images}/upload-attendance-verification.svg`" alt="" width="18" style="margin: 0px 7px 3px 0px">
                                Upload
                            </button> -->
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('download-upload-attendance-verification')">Cancel</button>
                </div>
            </div>
        </div>

        <div id="loader-upload-verification-attendance" class="loader-upload-verification-attendance" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <p class="uk-text-center">uploading...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <UploadStatus
            v-if="uploadSummary.isShowStatus"
            :data="uploadSummary.data"
            @hideModal="(uploadSummary = { isShowStatus: false, data: {} })"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {getUserLogin} from '@/utils/auth';
import {PREFIX_IMAGE} from '@/utils/constant';
// import LabelStatus from '@/components/globals/LabelStatus';
import formatter from "@/utils/formatter";
import Datepicker from 'vuejs-datepicker';
import {Datetime} from 'vue-datetime';
import {notificationDangerCustom, notificationSuccess, notificationDanger} from '@/utils/notification';
import {excelDownloader, excelDownloaderWithAdditionalSheet} from '@/utils/helper';
import {DateTime} from 'luxon';
import XLSX from 'xlsx';
import moment from 'moment';
moment.locale('id');

export default {
    data() {
        return {
            filename: null,
            downloadAttendanceText: 'Download',
            downloadAttendanceIsLoading: false,
            user_company_id: [],
            isLock: false,
            isShowDetailUser: false,
            moment: moment,
            isShow: false,
            meta: this.defaultMeta(),
            user_profile: '',
            json_data: null,
            images: PREFIX_IMAGE,
            plans: [],
            managers: [],
            company_office: '',
            disabled_start_date: {
                to: new Date('2020','07','01') // 1 August 2020
            },
            uploadSummary: {
                isShowStatus: false,
                data: [],
            },
            json_fields: {
                'WH ID': {
                    callback: (value) => {
                        return `${value.company_office.type.code} - ${value.company_office.code}`;
                    }
                },
                'WH Name': 'company_office.name',
                'Employee Name': 'user.fullname',
                'Over time (hours)': 'overtime_work_hour_spv',
                'Total Time spent (hours)': {
                    callback: (value) => {
                        return `${value.normal_work_hour + value.overtime_work_hour}`;
                    }
                }
            },
            json_fields_full: {
                'WH ID': {
                    callback: (value) => {
                        return `${value.company_office.type.code} - ${value.company_office.code}`;
                    }
                },
                'WH Name': 'company_office.name',
                'Employee Name': 'user.fullname',
                'Date': {
                    callback: (value) => {
                        return `${formatter.dateComplete(value.date)}`;
                    }
                },
                'Check-In time': {
                    callback: (value) => {
                        return `${formatter.timeComplete(value.checkin_time)}`;
                    }
                },
                'Check-Out time': {
                    callback: (value) => {
                        return `${formatter.timeComplete(value.checkout_time)}`;
                    }
                },
                'Normal Worktime (hours)': 'normal_work_hour',
                'Over time (hours)': 'overtime_work_hour_spv',
                'Absent (hours)': 'absent_hour',
                'Leaves (hours)': 'leaves_hour',
                'Total Payment (Rp)': {
                    callback: (value) => {
                        if (value.checkin_time == null || value.checkout_time == null) {
                            return '-';
                        } else {
                            const total_fee = (value.fee_normal_hourly) + (value.overtime_work_hour_spv * value.fee_overtime_hourly);
                            return total_fee;
                        }
                    }
                },
                'Notes': {
                    callback: (value) => {
                        const regex = /\bn/;
                        if (value.notes.search(regex) == 0) {
                            return '-';
                        } else {
                            return value.notes;
                        }
                    }
                },
            },
            json_fields_weekly_report: {
                'WH ID': 'wh_code',
                'WH Name': 'wh_name',
                'Week Start Date': 'start_date',
                'Week End Date': 'end_date',
                'Number of Employees': 'worker_total',
                'Normal Work Hours': 'normal_work_hour',
                'Overtime Work Hours': 'overtime_work_hour_spv'
            },
            selected_city: '',
            cities: [],
            filtered_offices: [],
            selected_month: '',
            selected_year: '',
            selected_data: {
                user: {},
                company: {},
                company_office: {},
                job_partner: {
                    role: {}
                }
            },
            timeIsInvalid: false,
            reportMonthly: false,
            userReport: false,
            userList: [],
            userName: '',
            salary_data: null,
            formSalary: {
                name: null,
            },
            showResult: false,
            datetime: DateTime,
            isLoading: false
        };
    },
    components: {
        // LabelStatus,
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Pagination: () => import('@/components/globals/Pagination'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        UploadStatus: () => import('./modals/UploadStatusVerif'),
        Datepicker,
        Datetime
    },
    computed : {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            recaps: 'mitra/recaps'
        }),
        user_cred() {
            return getUserLogin();
        },
        cityName() {
            let name = 'All Cities';

            if ((this.meta.domicile_city_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((office) => {
                    if (
                        (office && office.domicile_city_id && office.domicile_city_id._id) &&
                        (office.domicile_city_id._id === this.meta.domicile_city_id)
                    ) {
                        return office;
                    }
                });

                if (findCity && findCity.domicile_city_id && findCity.domicile_city_id.name) {
                    name = findCity.domicile_city_id.name;
                } else {
                    name = 'All Cities';
                }
            } else {
                name = 'All Cities';
            }

            return name;
        },
        officeName() {
            let name = 'All Offices';

            if ((this.meta.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findOffice = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.meta.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findOffice && findOffice.name) {
                    name = findOffice.name;
                } else {
                    name = 'All Offices';
                }
            } else {
                name = 'All Offices';
            }

            return name;
        },
        totalWorker() {
            return this.recaps.dashboard ? this.recaps.dashboard.total_worker ? this.recaps.dashboard.total_worker : 0 : 0;
        },
        totalWorkerOvertime() {
            return this.recaps.dashboard ? this.recaps.dashboard.total_overtime ? this.recaps.dashboard.total_overtime : 0 : 0;
        },
        totalWorkerLeave() {
            return this.recaps.dashboard ? this.recaps.dashboard.total_leave ? this.recaps.dashboard.total_leave : 0 : 0;
        },
    },
    watch: {
        async meta() {
            this.applyFilter();
        },
    },
    async mounted() {
        if (this.meta.start_date) {
            this.meta.start_date = moment( this.meta.start_date).format('YYYY-MM-DD');
        }
        if (this.meta.end_date) {
            this.meta.end_date = moment( this.meta.end_date).format('YYYY-MM-DD');
        }
        
        await this.getCompanyOffice();
        this.user_profile = await this.getProfile();
        this.setCity();
        this.mapQuery();
        if (Object.keys(this.$route.query).length !== 0) {
            this.isShow = true;
            await this.getMitraRecaps();
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.attendance-filter')) window.UIkit.modal('.attendance-filter').$destroy(true);
        if (window.UIkit.modal('.confirmation-multiple-lock-box')) window.UIkit.modal('.confirmation-multiple-lock-box').$destroy(true);
        if (window.UIkit.modal('.download-upload-attendance-verification')) window.UIkit.modal('.download-upload-attendance-verification').$destroy(true);
        if (window.UIkit.modal('.loader-upload-verification-attendance')) window.UIkit.modal('.loader-upload-verification-attendance').$destroy(true);
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'company_office/getCompanyOffice',
            getProfile: 'auth/getProfile',
            getRecapsAttendance: 'mitra/getRecapsAttendance',
            getRecapsDetail: 'mitra/getRecapsDetailReport',
            getAllRecaps: 'mitra/getAllRecaps',
            getWeeklyReportByMonth: 'mitra/getWeeklyReportByMonth',
            getDailyReportByMonth: 'mitra/getDailyReportByMonth',
            getAllNonGroupedRecaps: 'mitra/getAllNonGroupedRecaps',
            updateRecap: 'mitra/updateRecap',
            getUserPartner: 'schedule/getUserPartner',
            checkRecaps: 'salary/checkRecaps',
            postCreateBatch: 'salary/postCreateBatch',
            getRecapsReport: 'mitra/getRecapsReport',
            updateMultipleLock: 'mitra/updateMultipleLock',
            uploadAttendanceVerification: 'mitra/uploadAttendanceVerification',
            getDailyAttendanceReport: 'report/getDailyAttendanceReport',
        }),
        defaultMeta() {           
            return {
                limit: this.$route.query.limit && this.$route.query.limit !== 'null' ? this.$route.query.limit : 10,
                page: this.$route.query.page && this.$route.query.page !== 'null' ? this.$route.query.page : 1,
                start_date: this.$route.query.start_date && this.$route.query.start_date !== 'null' ? this.$route.query.start_date : null,
                end_date: this.$route.query.end_date && this.$route.query.end_date !== 'null' ? this.$route.query.end_date : null,
                year: this.$route.query.year && this.$route.query.year !== 'null' ? this.$route.query.year : new Date().getFullYear(), // need to change
                hour_diff: this.$route.query.hour_diff && this.$route.query.hour_diff !== 'null' ? this.$route.query.hour_diff : DateTime.local().offset / 60,
                worker_name: this.$route.query.worker_name && this.$route.query.worker_name !== 'null' ? this.$route.query.worker_name : '',
                updated_filter: this.$route.query.updated_filter && this.$route.query.updated_filter !== 'null' ? this.$route.query.updated_filter : null,
                sort: this.$route.query.sort && this.$route.query.sort !== 'null' ? this.$route.query.sort : '-1',
                company_office_id: this.$route.query.company_office_id && this.$route.query.company_office_id !== 'null' ? this.$route.query.company_office_id : null,
                domicile_city_id: this.$route.query.domicile_city_id && this.$route.query.domicile_city_id !== 'null' ? this.$route.query.domicile_city_id : null
            };
        },
        async uploadFileAttendanceVerification(file) {
            try {
                let data = new FormData();
                data.append('company_office_id', this.user_profile._id);
                data.append('excel', file);
                const result = await this.uploadAttendanceVerification(data);
                if (result.status === 'OK' && result.data && result.data.failedUser && result.data.failedUser.length < 1) {
                    notificationSuccess(`Upload successful. Verif attendance uploaded!`);
                } else if (result.data.failedUser && result.data.failedUser.length > 0) {                   
                    this.uploadSummary.isShowStatus = true;
                    this.uploadSummary.data = result.data;
                } else {
                    notificationDangerCustom('Failed to upload attendance verification!');
                }
                setTimeout(() => {
                    this.hideModal('loader-upload-verification-attendance');
                    this.filename = null;
                }, 500);
            } catch (error) {
                notificationDangerCustom(error);
            } finally {
                this.$refs.upload.value = null;
                this.applyFilter();
            }
        },
        async uploadFileVerification(e) {
            if (e.target.files) {
                const filepath = e.target.files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    /* Parse data */
                    const bstr = event.target.result;
                    const wb = XLSX.read(bstr, {type: 'binary'});
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws, {header: 1});
                    this.filename = e.target.files[0];
                    this.showModal('loader-upload-verification-attendance');
                    this.uploadFileAttendanceVerification(e.target.files[0], data.length - 1);
                };
                reader.readAsBinaryString(filepath);
            }
        },
        triggerUpload() {
            this.$refs.upload.click();
        },
        async acceptConfirmationLockUser() {
            this.isLock = false;
            const response = await this.updateMultipleLock(this.user_company_id);
            if (response && response.status === 'OK') {
                notificationSuccess('User attendance has been locked!');
                this.hideModal('confirmation-multiple-lock-box');
                this.user_company_id = [];
            } else {
                notificationDangerCustom('Failed to lock user attendance.');
            }
        },
        declineConfirmationLockUser() {
            this.hideModal('confirmation-multiple-lock-box');
        },
        cbListCandidate(e, worker) {
            if (e.target.checked) {
                this.user_company_id.push({
                    user_id: worker.employee_id,
                    company_office_id: worker.company_office_id,
                    job_partner_id: worker.job_partner_id
                });
            } else {
                this.user_company_id.splice(this.user_company_id.findIndex(item => item.employee_id === worker.employee_id), 1);
            }
        },
        async getMitraRecaps() {
            this.isLoading = true;
            await this.getRecapsAttendance(this.meta);
            this.isLoading = false;
        },
        filterAttendanceMethods(status){
            switch (status) {
            case 0:
                return 'Scanned by employer spv';
            case 1:
                return 'Self-scan on office/warehouse';
            case 2:
                return 'Photo upload';
            case 3:
                return 'Mitra Request';
            default:
                return 'No Attendance Methods';
            }
        },
        detailCandidate(data) {
            this.meta.worker_name = data.employee_name;
            this.meta.page = 1;
            this.meta.start_date = this.moment(this.meta.start_date).format('YYYY-MM-DD');
            this.meta.end_date = this.moment(this.meta.end_date).format('YYYY-MM-DD') === 'Invalid date' ? null : this.moment(this.meta.end_date).format('YYYY-MM-DD');
            this.meta.job_partner_id = data.job_partner_id;
            this.$router.push({ path: `candidate/${data.employee_name}`, query: this.meta });
        },
        async fetchUser(){
            this.userList = await this.getUserPartner({company_office_id: this.company_office._id});
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        selectCompanyOffice(){
            this.company_office = this.company_offices.length > 0 ? this.company_offices[0] : 'n/a';
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatHourMinute(data) {
            if (data == null) {
                return '-';
            }
            return formatter.hourMinuteOnly(data);
        },
        matchMonth(date, month) {
            month--;
            if (date.getMonth() === month) return date;

            // End of year
            if (date.getMonth() === 11 && month === 0) {
                return new Date(date.getFullYear() + 1, month, 1);
            } else if (date.getMonth() === 0 && month === 11) {
                return new Date(date.getFullYear() - 1, month + 1, 0);
            }

            // Rest of year
            if (date.getMonth() < month) {
                return new Date(date.getFullYear(), month, 1);
            } else {
                return new Date(date.getFullYear(), month + 1, 0);
            }
        },
        formatCurrency(data) {
            if (data == ('-' || null)) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        formatAbsolute(data) {
            return formatter.absoluteNumber(data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        redirectPage(link) {
            window.open(link, '_blank');
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'loader-upload-verification-attendance':
                await window.UIkit.modal('#loader-upload-verification-attendance').hide();
                break;
            case 'download-upload-attendance-verification':
                await window.UIkit.modal('#download-upload-attendance-verification').hide();
                break;
            case 'confirmation-multiple-lock-box':
                await window.UIkit.modal('#confirmation-multiple-lock-box').hide();
                break;
            case 'date-filter':
                await window.UIkit.modal('#date-filter').hide();
                break;
            case 'location-filter':
                await window.UIkit.modal('#location-filter').hide();
                break;
            case 'attendance-filter':
                await window.UIkit.modal('#attendance-filter').hide();
                break;
            case 'month-filter':
                this.reportMonthly = false;
                this.userReport = false;
                await window.UIkit.modal('#month-filter').hide();
                break;
            case 'show-detail':
                await window.UIkit.modal('#show-detail').hide();
                this.selected_data= {
                    user: {},
                    company: {},
                    company_office: {},
                    job_partner: {
                        role: {}
                    }};
                this.timeIsInvalid = false;
                break;
            case 'confirmation-edit-box':
                await window.UIkit.modal('#confirmation-edit-box').hide();
                break;
            case 'confirmation-edit-box-2':
                await window.UIkit.modal('#confirmation-edit-box-2').hide();
                break;
            case 'confirmation-heads-up':
                await window.UIkit.modal('#confirmation-heads-up').hide();
                break;
            case 'success-edit':
                await window.UIkit.modal('#success-edit').hide();
                break;
            case 'download-modal':
                await window.UIkit.modal('#download-modal').hide();
                break;
            case 'warning-modal':
                await window.UIkit.modal('#warning-modal').hide();
                this.salary_data = null;
                break;
            case 'salary-modal':
                await window.UIkit.modal('#salary-modal').hide();
                this.salary_data = null;
                this.formSalary = {name:null};
                break;
            }
        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'loader-upload-verification-attendance':
                await window.UIkit.modal('#loader-upload-verification-attendance').show();
                break;
            case 'download-upload-attendance-verification':
                await window.UIkit.modal('#download-upload-attendance-verification').show();
                break;
            case 'confirmation-multiple-lock-box':
                if (this.user_company_id.length === 0) {
                    notificationDangerCustom('You havent chosen mitra');
                } else {
                    await window.UIkit.modal('#confirmation-multiple-lock-box').show();
                }
                break;
            case 'date-filter':
                this.showResult = false;
                await window.UIkit.modal('#date-filter').show();
                break;
            case 'location-filter':
                this.showResult = false;
                this.setCity();
                if (this.selected_city !== '') {
                    this.filterOffice();
                    this.mapQuery();
                }
                await window.UIkit.modal('#location-filter').show();
                break;
            case 'attendance-filter':
                this.showResult = false;
                this.setCity();
                if (this.selected_city !== '') {
                    this.filterOffice();
                    this.mapQuery();
                }
                await window.UIkit.modal('#attendance-filter').show();
                break;
            case 'month-filter':
                await window.UIkit.modal('#month-filter').show();
                break;
            case 'show-detail':
                await window.UIkit.modal('#show-detail').show();
                break;
            case 'confirmation-edit-box':
                await window.UIkit.modal('#confirmation-edit-box').show();
                break;
            case 'confirmation-edit-box-2':
                await window.UIkit.modal('#confirmation-edit-box-2').show();
                break;
            case 'confirmation-heads-up':
                await window.UIkit.modal('#confirmation-heads-up').show();
                break;
            case 'success-edit':
                await window.UIkit.modal('#success-edit').show();
                break;
            case 'download-modal':
                this.fetchUser();
                await window.UIkit.modal('#download-modal').show();
                break;
            case 'warning-modal':
                await window.UIkit.modal('#warning-modal').show();
                break;
            case 'salary-modal':
                await window.UIkit.modal('#salary-modal').show();
                break;
            }
        },
        setDate() {
            if (this.meta.start_date != null && this.meta.end_date != null) {
                const start_date = new Date(this.meta.start_date);
                const end_date = new Date(this.meta.end_date);
                if (start_date > end_date) {
                    return notificationDangerCustom('End date must be greater than start date');
                }
            } else if (this.meta.start_date == null) {
                return notificationDangerCustom('Start date must be filled');
            }
            this.getMitraRecaps();
            this.showResult = true;
            this.hideModal('date-filter');

        },
        async downloadData() {
            const response = await this.getAllRecaps(this.meta);
            this.json_data = await this.getAllNonGroupedRecaps(this.meta);
            this.$refs.download2.$el.click();
            return response;
        },
        async downloadDataWeekly() {
            this.hideModal('month-filter');
            const response = await this.getWeeklyReportByMonth(this.meta);
            return response;
        },
        startDownload(){
            notificationSuccess('Downloading data.....');
        },
        finishDownload(){
            notificationSuccess('Data downloaded successfully');
        },
        dateFilterAsText() {
            let date_format = '';
            if (this.meta.start_date) {
                date_format = date_format + this.formatDate(this.meta.start_date);
            }
            if (this.meta.end_date) {
                if (date_format !== '') {
                    date_format = date_format + '-';
                }
                date_format = date_format + this.formatDate(this.meta.end_date);
            }
            return date_format;
        },
        generateFileName() {
            let suffixName = "";
            const officeCity = this.company_office ? this.company_office.city : '';
            const officeCode = this.company_office ? this.company_office.code : '';
            if (this.dateFilterAsText() != '' && this.company_office == '') {
                suffixName = ` (${this.dateFilterAsText()})`;
            } else if (this.dateFilterAsText() == '' && this.company_office != '') {
                suffixName = ` ${officeCity} ${officeCode}`;
            } else if (this.dateFilterAsText() != '' && this.company_office != '') {
                suffixName = ` ${officeCity} ${officeCode} (${this.dateFilterAsText()})`;
            }

            let filename = `MyRobin-Shipper Mitra Attendance Report${suffixName}.xls`;
            return filename;
        },
        generateFileNameFull() {
            let suffixName = "";
            const officeCity = this.company_office ? this.company_office.city: '';
            const officeCode = this.company_office ? this.company_office.code : '';
            if (this.dateFilterAsText() != '' && this.company_office == '') {
                suffixName = ` (${this.dateFilterAsText()})`;
            } else if (this.dateFilterAsText() == '' && this.company_office != '') {
                suffixName = ` ${officeCity} ${officeCode}`;
            } else if (this.dateFilterAsText() != '' && this.company_office != '') {
                suffixName = ` ${officeCity} ${officeCode} (${this.dateFilterAsText()})`;
            }

            let filename = `MyRobin-Shipper Mitra Attendance Details Report${suffixName}.xls`;

            return filename;
        },
        resetDate() {
            this.meta.start_date = null;
            this.meta.end_date = null;
            this.getMitraRecaps();
        },
        flterDateText() {
            let date_filter_text = this.dateFilterAsText();
            if (date_filter_text != '') {
                return date_filter_text;
            }
            return 'Choose date range';
        },
        setCity() {
            if (this.company_offices && this.company_offices.length > 0) {
                this.company_offices.forEach((office) => {
                    const findCity = this.cities.find((data) => {
                        if ((office.domicile_city_id && office.domicile_city_id._id) && (data._id === office.domicile_city_id._id)) {
                            return data;
                        }
                    });
                    if ((!findCity) && (office.domicile_city_id && office.domicile_city_id._id && office.domicile_city_id.name)) {
                        this.cities.push({
                            _id: office.domicile_city_id._id,
                            name: office.domicile_city_id.name
                        });
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }

            this.filtered_offices = [];
        },
        filterOffice() {
            this.company_office = '';

            if (this.company_offices && this.company_offices.length > 0) {
                const offices = this.company_offices.filter((office) => {
                    if (
                        (office.domicile_city_id && office.domicile_city_id._id) &&
                        (this.selected_city && this.selected_city._id) &&
                        (office.domicile_city_id._id === this.selected_city._id)
                    ) {
                        return office;
                    }
                });
                this.filtered_offices = offices;
            }

            if (this.filtered_offices.length > 0) {
                this.filtered_offices.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
            }
        },
        async applyFilter() {
            if (!this.meta.start_date) {
                return notificationDangerCustom('Start date must be filled');
            }

            if (this.meta.start_date && !this.meta.end_date) {
                const start_date = moment(this.meta.start_date).add(this.meta.hour_diff, 'hours').format();
                const end_date = moment(this.meta.start_date).add(30, 'days').format();

                this.meta.start_date = start_date;
                this.meta.end_date = end_date;
            } else if (this.meta.start_date && this.meta.end_date) {
                const start_date = moment(this.meta.start_date).add(this.meta.hour_diff, 'hours').format();
                const end_date = moment(this.meta.end_date).add(this.meta.hour_diff, 'hours').format();

                if (start_date > end_date) {
                    return notificationDangerCustom('End date must be greater than start date');
                }

                this.meta.start_date = start_date;
                this.meta.end_date = end_date;
            }

            this.meta.company_office_id = this.company_office ? this.company_office._id ? this.company_office._id : null : null;
            this.meta.domicile_city_id = this.selected_city ? this.selected_city._id ? this.selected_city._id : null : null;
            this.meta.start_date = moment(this.meta.start_date).format('YYYY-MM-DD');
            this.meta.end_date = moment(this.meta.end_date).format('YYYY-MM-DD');

            this.showResult = true;
            this.isShow = true;
            this.$store.state.selected_company = this.company_office;

            const queryString = Object.keys(this.meta).map(key => key + '=' + this.meta[key]).join('&');
            await this.$router.replace(this.$route.path + '?reset');
            await this.$router.replace(this.$route.path + `?${queryString}`);

            await this.getMitraRecaps();
            await this.hideModal('attendance-filter');
        },
        async setLocation() {
            if (this.company_office === '') {
                return notificationDangerCustom('Please select one of property');
            }

            this.meta.company_office_id = this.company_office ? this.company_office._id ? this.company_office._id : null : null;
            await this.getMitraRecaps();
            this.showResult = true;
            await this.hideModal('location-filter');
            this.$store.state.selected_company = this.company_office;
        },
        async resetFilter() {
            this.company_office = '';
            this.selected_city = '';
            this.cities = [];
            this.filtered_offices = [];
            this.$store.state.selected_company = '';
            this.meta.start_date = null;
            this.meta.end_date = null;
            this.meta.company_office_id = null;
            this.meta.domicile_city_id = null;
            await this.getMitraRecaps();
            this.isShow = false;
            this.$router.replace({'query': null});
        },
        changeMeta(e, prop) {
            if(prop == 'worker_name') {
                e.target.value = formatter.sanitizeString(e.target.value);
            }
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        generateMonthlyFileName() {
            if (this.userReport) {
                return `MyRobin-Shipper Monthly Report (${this.userName} - ${this.monthName(this.selected_month)} - ${this.selected_year}).xls`;
            } else {
                return `MyRobin-Shipper Monthly Report (${this.monthName(this.selected_month)} - ${this.selected_year}).xls`;
            }
        },
        generateWeeklyFileName() {
            if (this.userReport) {
                return `MyRobin-Shipper User Weekly Report (${this.userName} -  ${this.monthName(this.selected_month)} - ${this.selected_year}).xls`;
            } else {
                return `MyRobin-Shipper Weekly Report (${this.monthName(this.selected_month)} - ${this.selected_year}).xls`;
            }
        },
        monthName(mon) {
            return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
        },
        editDataTreshold(date) {
            return moment().subtract(2, "days") < moment(date);
        },
        selectData(data) {
            this.selected_data = {...data};
            this.showModal('confirmation-edit-box');
        },
        calculateFee(data) {
            // const worktime = parseInt(Math.abs(data.normal_work_hour || 0));
            const worktime_payment = parseInt(Math.abs(data.fee_normal_hourly || 0));
            const overtime = parseInt(Math.abs(data.overtime_work_hour_spv || 0));
            const overtime_payment = parseInt(Math.abs(data.fee_overtime_hourly || 0));
            const total_fee = ( worktime_payment ) + ( overtime * overtime_payment );
            return this.formatCurrency(parseInt(total_fee));
        },
        async updateData() {
            const data = {
                id: this.selected_data.id,
                checkin_time: this.selected_data.checkin_time,
                checkout_time: this.selected_data.checkout_time,
                normal_work_hour: this.selected_data.normal_work_hour,
                overtime_work_hour_spv: this.selected_data.overtime_work_hour_spv,
                absent_hour: this.selected_data.absent_hour,
                leaves_hour: this.selected_data.leaves_hour,
            };
            const response = await this.updateRecap(data);
            if (response.status == 'OK') {
                await this.getMitraRecaps();
                this.hideModal('confirmation-heads-up');
            }
        },
        async lockData() {
            const data = {
                id: this.selected_data.id,
                checkin_time: this.selected_data.checkin_time,
                checkout_time: this.selected_data.checkout_time,
                normal_work_hour: this.selected_data.normal_work_hour,
                overtime_work_hour_spv: this.selected_data.overtime_work_hour_spv,
                absent_hour: this.selected_data.absent_hour,
                leaves_hour: this.selected_data.leaves_hour,
            };
            const response = await this.updateRecap(data);
            if (response.status == 'OK') {
                await this.getMitraRecaps();
                this.hideModal('confirmation-heads-up');
                this.showModal('success-edit');
            }
        },
        acceptConfirmationEdit() {
            this.hideModal('confirmation-edit-box');
            this.showModal('show-detail');
        },
        async acceptConfirmationEdit2() {
            const data = {
                id: this.selected_data.id,
                checkin_time: this.selected_data.checkin_time,
                checkout_time: this.selected_data.checkout_time,
                normal_work_hour: this.selected_data.normal_work_hour,
                overtime_work_hour_spv: this.selected_data.overtime_work_hour_spv,
                absent_hour: this.selected_data.absent_hour,
                leaves_hour: this.selected_data.leaves_hour,
            };
            let response = await this.updateRecap(data);
            if (response.status == 'OK') {
                await this.getMitraRecaps();
                this.hideModal('confirmation-edit-box-2');
                this.showModal('success-edit');
            }
        },
        declineConfirmationEdit() {
            this.hideModal('confirmation-edit-box');
            this.showModal('confirmation-heads-up');
        },
        declineConfirmationEdit2() {
            this.hideModal('confirmation-edit-box-2');
            this.showModal('show-detail');
        },
        async updateDataConfirm() {
            await window.UIkit.modal('#show-detail').hide();
            this.showModal('confirmation-edit-box-2');
        },
        calculateTotalWorkTime(normal, overtime) {
            const normal_time = parseInt(Math.abs(normal)) || 0;
            const overtime_time = parseInt(Math.abs(overtime)) || 0;
            return normal_time + overtime_time;
        },
        adjustWorktime(e, type) {
            let checkin = '';
            let checkout = '';

            switch(type) {
            case 'check-in':
                checkin = e.target.value;
                checkout = this.selected_data.checkout_time;
                break;
            case 'check-out':
                checkin = this.selected_data.checkin_time;
                checkout = e.target.value;
                break;
            }

            const checkin_time = new Date(checkin);
            const checkout_time = new Date(checkout);

            if (checkin_time > checkout_time) {
                if(type == 'check-in') {
                    return notificationDangerCustom('Check in time must be lesser than check out time');
                } else
                    return notificationDangerCustom('Check out time must be greater than check in time');
            }

            const time_dif = (checkout_time - checkin_time) / 3600000; // milisecond
            this.selected_data.checkin_time = checkin;
            this.selected_data.checkout_time = checkout;
            this.selected_data.normal_work_hour = Math.floor(time_dif);
        },
        adjustCheckOutTime(value) {
            const checkin_time = new Date(this.selected_data.checkin_time);
            const checkout_time = new Date(value);

            if (checkin_time > checkout_time) {
                this.timeIsInvalid = true;
                return notificationDangerCustom('Check out time must be greater than check in time');
            }

            this.timeIsInvalid = false;
            const time_dif = (checkout_time - checkin_time) / 3600000; // milisecond
            this.selected_data.checkout_time = value;
            this.selected_data.normal_work_hour = Math.floor(time_dif);
        },
        adjustCheckInTime(value) {
            const checkin_time = new Date(value);
            const checkout_time = new Date(this.selected_data.checkout_time);

            if (checkin_time > checkout_time) {
                this.timeIsInvalid = true;
                return notificationDangerCustom('Check in time must be lesser than check out time');
            }

            this.timeIsInvalid = false;
            const time_dif = (checkout_time - checkin_time) / 3600000; // milisecond
            this.selected_data.checkin_time = value;
            this.selected_data.normal_work_hour = Math.floor(time_dif);
        },
        checkUpdatedBy(data) {
            if (data.employer_hr != null) {
                return data.employer_hr.fullname;
            } else if (data.employer_spv != null) {
                return data.employer_spv.fullname;
            } else {
                return '-';
            }
        },
        async downloadAttendanceVerification() {
            const start_date = moment(this.meta.start_date);
            const end_date = moment(this.meta.end_date);
            const calcDiff = end_date.diff(start_date, 'days') + 1;
            this.downloadAttendanceText = 'Downloading...';
            this.downloadAttendanceIsLoading = true;
            this.meta.limit = 1000;
            this.meta.page = 1;                        
            try {
                let allUserReport = await this.getRecapsReport(this.meta);
                const dataExcel = [];
                const additionalData = [
                    {
                        sheet_name: 'Guide',
                        data: [
                            {
                                'Field': 'Checkin',
                                'Contoh': '22:19 2025-01-15',
                                'Tipe Data': 'Date (hh:mm mm/dd/yyyy)',
                            },
                            {
                                'Field': 'Checkout',
                                'Contoh': '05:19 2025-01-16',
                                'Tipe Data': 'Date (hh:mm mm/dd/yyyy)',
                            },
                            {
                                'Field': 'Overtime',
                                'Contoh': '2',
                                'Tipe Data': 'Text'
                            }
                        ]
                    }
                ];
                for (const el of allUserReport.docs) {                    
                    const obj = {
                        "USER ID": el.employee_id, //
                        "NAMA": el.employee_name,
                        "EMAIL": el.employee_email,
                        "NIK": el.identity_number,
                        "JOB PARTNER ID": el.job_partner_id,
                        "ROLE ID": el.role_id,
                        "ROLE": el.job_role,
                        "COMPANY OFFICE ID": el.company_office_id,
                        "OFFICE": el.wh_name, //
                    };

                    this.meta.job_partner_id = el.job_partner_id;
                    this.meta.worker_name = el.employee_name;
                    
                    let attendanceUserDetail = await this.getRecapsDetail(this.meta);

                    for(let i = 1; i <= calcDiff; i++) {
                        const loopDate = moment(this.meta.start_date).add(i-1, 'days').format('YYYY-MM-DD');
                        if (attendanceUserDetail.docs.length > 0) {
                            const compareDate = attendanceUserDetail.docs.find(x => moment(x.date).format('YYYY-MM-DD') === loopDate);
                            if (compareDate) {                                
                                obj[`checkin_${loopDate}`] = compareDate.checkin_time ? new Date(compareDate.checkin_time) : null;
                                obj[`checkout_${loopDate}`] = compareDate.checkout_time ? new Date(compareDate.checkout_time) : null;
                                obj[`overtime_${loopDate}`] = compareDate.status === 1 ? compareDate.overtime_work_hour_spv : compareDate.overtime_work_hour ? compareDate.overtime_work_hour : 0;
                            } else {
                                obj[`checkin_${loopDate}`] = null;
                                obj[`checkout_${loopDate}`] = null;
                                obj[`overtime_${loopDate}`] = null;
                            }
                            continue;
                        }
                        obj[`checkin_${loopDate}`] = '';
                        obj[`checkout_${loopDate}`] = '';
                        obj[`overtime_${loopDate}`] = '';
                    }
                    dataExcel.push(obj);
                }
                excelDownloaderWithAdditionalSheet(
                    dataExcel,
                    additionalData,
                    `AttendanceVerification_` +
                    `${this.user_profile.company_name}_` +
                    `${this.cityName}_` +
                    `${this.officeName}_` +
                    `${moment(this.meta.start_date).format('YYYY-MM-DD')} - ${moment(this.meta.end_date).format('YYYY-MM-DD')}.xls`
                );
            } catch (error) {
                notificationDanger(error);
            } finally {
                delete this.meta.job_partner_id;
                this.meta.worker_name = '';
                this.meta.limit = 10;
                this.hideModal('download-upload-attendance-verification');
                this.downloadAttendanceText = 'Download';
                this.downloadAttendanceIsLoading = false;
            }
        },
        async AllUserReport() {
            this.meta.worker_name = '';
            this.meta.page = 1;
            let allUserReport = await this.getRecapsReport(this.meta);
            const dataExcel = [];
            allUserReport.docs.forEach(el => {
                const obj = {
                    "DATE FROM": this.meta.start_date.length > 0 ? moment(this.meta.start_date).format('DD MMM YYYY') : '',
                    "DATE TO": this.meta.end_date.length > 0 ? moment(this.meta.end_date).format('DD MMM YYYY') : '',
                    "NAME": el.employee_name,
                    "ID NUMBER (KTP)": el.identity_number,
                    "PHONE NUMBER": el.phone_number, //
                    "JOB ROLE": el.job_role, //
                    "TOTAL WORKDAYS": el.workdays,
                    "TOTAL WORKHOURS": el.time_spent,
                    "TOTAL OVERTIME (hrs)": el.overtime_work_hour_spv,
                    "TOTAL LEAVE (times)": el.leaves_spent,
                    "OFFICE": el.wh_name,
                    "ATTENDANCE METHOD": this.filterAttendanceMethods(el.attendance_methods),
                };
                dataExcel.push(obj);
            });
            excelDownloader(dataExcel, `All_User_Report.xls`);
            this.meta.limit = 10;
        },
        async DailyAttendanceReport() {
            try {
                this.isLoading = true;
                const query = {
                    start_date: this.meta.start_date,
                    end_date: this.meta.end_date,
                    worker_name: this.meta.worker_name,
                    domicile_city_id: this.meta.domicile_city_id,
                    company_office_id: this.meta.company_office_id,
                    hour_diff: this.meta.hour_diff
                };

                const dataReport = await this.getDailyAttendanceReport(query);
                if (dataReport && dataReport.docs && dataReport.fileName) {
                    excelDownloader(dataReport.docs, dataReport.fileName);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async spesificUserReport(name, jobPartnerId) {
            this.meta.worker_name = name;
            this.meta.job_partner_id = jobPartnerId;
            this.meta.page = 1;
            let detailUserReport = await this.getRecapsDetail(this.meta);
            const dataExcel = [];
            await detailUserReport.docs.forEach(el => {
                const obj = {
                    "NAME": el.user.fullname,
                    "ID NUMBER (KTP)": el.user.identity_number,
                    "PHONE NUMBER": el.user.phone,
                    "ROLE": el.job_partner.role.name,
                    "OFFICE": el.company_office.name,
                    "WORKDATE": el.date ? moment(el.date).format('DD-MM-YYYY') : '-',
                    "CHECKIN TIME": el.checkin_time ? moment(el.checkin_time).format('HH:mm') : '-',
                    "CHECKOUT TIME": el.checkout_time ? moment(el.checkout_time).format('HH:mm') : '-',
                    "NORMAL WORKTIME": el.normal_work_hour,
                    "OVERTIME CALCULATED (hrs)": el.overtime_work_hour,
                    "OVERTIME ACCEPTED (hrs)": el.overtime_work_hour_spv,
                    "OVERALL WORKTIME (hrs)": this.calculateTotalWorkTime(el.normal_work_hour, el.overtime_work_hour),
                    "LEAVES (times)": el.leaves_hour,
                    "DISTANCE CHECKIN (METER)": el.distance_meter_checkin,
                    "DISTANCE CHECKOUT (METER)": el.distance_meter_checkout,
                    "CHECKIN NOTES": el.checkin_notes,
                    "CHECKOUT NOTES": el.checkout_notes,
                    "UPDATED BY": el.updated_by,
                };
                dataExcel.push(obj);
            });
            excelDownloader(dataExcel, `Report_Detail_User_${name.replace(/ /g, '_')}.xls`);
            this.meta.worker_name = '';
            this.meta.job_partner_id = null;
            this.meta.limit = 10;
        },
        async downloadNewExcelDaily() {
            let all_recaps = await this.getAllRecaps(this.meta);
            let non_grouped_recaps = await this.getAllNonGroupedRecaps(this.meta);

            //format time in frontend for excel file
            for (let item in non_grouped_recaps) {
                non_grouped_recaps[item].date = non_grouped_recaps[item].date !== '-' ? formatter.dateComplete(non_grouped_recaps[item].date) : '-';
                non_grouped_recaps[item].checkin_time = non_grouped_recaps[item].checkin_time !== '-' ? formatter.timeComplete(non_grouped_recaps[item].checkin_time) : '-';
                non_grouped_recaps[item].checkout_time= non_grouped_recaps[item].checkout_time !== '-' ? formatter.timeComplete(non_grouped_recaps[item].checkout_time) : '-';
            }
            excelDownloader(all_recaps, this.generateFileName());
            excelDownloader(non_grouped_recaps, this.generateFileNameFull());
        },
        async downloadNewExcelMonthly() {
            if (this.userReport) {
                if (this.userName !== '') {
                    this.meta.worker_name = this.userName;
                } else {
                    return notificationDangerCustom('User must be selected!');
                }
            }
            const weekly_recaps = await this.getWeeklyReportByMonth(this.meta);
            excelDownloader(weekly_recaps, this.generateWeeklyFileName());
            this.resetUserReport();
            this.hideModal('month-filter');
        },
        async downloadNewDaytoDayExcelMonthly() {
            if (this.userReport) {
                if (this.userName !== '') {
                    this.meta.worker_name = this.userName;
                } else {
                    return notificationDangerCustom('User must be selected!');
                }
            }
            this.reportMonthly = false;
            let weekly_recaps = await this.getDailyReportByMonth(this.meta);
            excelDownloader(weekly_recaps, this.generateMonthlyFileName());
            this.resetUserReport();
            this.hideModal('month-filter');
        },
        resetUserReport() {
            this.userReport = false;
            this.meta.worker_name = '';
            this.userName = '';
        },
        async checkRecap() {
            this.salary_data =  await this.checkRecaps({recaps: this.recaps.docs});
            if (this.salary_data.status == 'error') {
                this.showModal('warning-modal');
            } else {
                this.showModal('salary-modal');
            }
        },
        async createSalaryBatch() {
            const response = await this.postCreateBatch({name: this.formSalary.name, recaps: this.recaps.docs});
            if (response && response.status === 'OK') {
                notificationSuccess('Salary batch created.');
                this.hideModal('salary-modal');
                await this.getMitraRecaps();
            } else {
                notificationDangerCustom('Failed to create salary batch.');
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        mapQuery() {
            if ((this.$route.query.company_office_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findOffice = this.company_offices.find((office) => {
                    if (
                        (office && office._id) &&
                        (office._id === this.$route.query.company_office_id)
                    ) {
                        return office;
                    }
                });

                if (findOffice) {
                    this.company_office = findOffice;
                }
            }

            if ((this.$route.query.domicile_city_id) && (this.company_offices && this.company_offices.length > 0)) {
                const findCity = this.company_offices.find((office) => {
                    if (
                        (office && office.domicile_city_id && office.domicile_city_id._id) &&
                        (office.domicile_city_id._id === this.$route.query.domicile_city_id)
                    ) {
                        return office;
                    }
                });

                if ((findCity && findCity.domicile_city_id) && (findCity.domicile_city_id._id && findCity.domicile_city_id.name)) {
                    const city = {
                        _id: findCity.domicile_city_id._id,
                        name: findCity.domicile_city_id.name
                    };

                    this.selected_city = city;
                }
            }
            
        }
    }
};
</script>

<style scoped>
div{
   margin-bottom: 10px;
}
.card-title-style{
   color: #0ABAB5;
   font-size: 30px;
   font-style: 'bold';
   font-family: 'Roboto';
}
.location-header{
    font-family: 'Roboto';
    color: #FEBE10;
    font-size: 20px;
}
thead{
    background-color: #0ABAB5;
}
th{
    color: #ffffff;
}
td{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.reset-filter{
   font-family: 'Roboto';
   color: #979797;
   text-decoration: underline;
   font-size: 16px;
   cursor: pointer;
   margin: 0;
   padding: 0;
}
.report-download{
    font-family: 'Roboto';
    color: #5FD855;
    font-size: 20px;
}
</style>
